<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LOAD STOCK OF CAR</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout col wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              v-if="type==='Car'"
              v-model="car_brand"
              class="mx-2"
              dense
              outlined
              label="Car"
              :items="car_brand_items"
              item-value="car_brand"
              item-text="car_brand"
              :rules="rules.combobox_rule"
            ></v-select>
            <v-text-field
              class="mx-2"
              v-model="auto_unit"
              label="Auto Unit"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_mv_file_no"
              label="MV File #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-checkbox label="Is Temporary Plate" hide-details class="me-3 mt-1"
                        v-model="is_temporary"></v-checkbox>
            <v-text-field
              class="mx-2"
              v-model="auto_plate_no"
              label="Plate #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_unit_color"
              label="Unit Color"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_chasis_no"
              label="Chasis #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_engine_no"
              label="Engine #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="year_model"
              label="Year Model"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              min="0"
              type="number"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="car_brand_date_procure"
              label="Date Procure"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="auto_unit_value"
              label="Unit Value"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
            ></v-text-field>
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"
              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                class="w-full"
                color="primary"
                @click="save_request"
                v-if="!saving_data"
              >
                Save Changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-flex>
          <v-flex xs12 md8>
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
                          height="500"
            >
              <template v-slot:item.total="{ item }">
                <tr>
                  <td>
                    {{ formatPrice(item.car_brand_unit_price*item.car_brand_unit_quantity) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-form>

    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      is_temporary: false,
      alert: false,
      alert_message: '',

      year_model: '0',
      auto_unit_value: '0',
      auto_engine_no: '',
      auto_chasis_no: '',
      auto_unit_color: '',
      auto_plate_no: '',
      auto_mv_file_no: '',
      auto_unit: '',
      auto_dealer: '',
      car_brand: '',
      car_brand_date_procure: '',
      type: 'Car',

      headers: [
        {text: 'Auto Dealer', value: 'auto_dealer', sortable: true},
        {text: 'Auto Unit', value: 'auto_unit', sortable: true},
        {text: 'MV File', value: 'auto_mv_file_no', sortable: true},
        {text: 'Plate #', value: 'auto_plate_no', sortable: true},
        {text: 'Color', value: 'auto_unit_color', sortable: true},
        {text: 'Chasis #', value: 'auto_chasis_no', sortable: true},
        {text: 'Engine #', value: 'auto_engine_no', sortable: true},
        {text: 'Value', value: 'auto_unit_value', sortable: true},
        {text: 'Year Model', value: 'year_model', sortable: true},
      ],
      data_items: [],
      car_brand_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.selected_type('Car')
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory', ['initialize_load_stock', 'register_stock']),
      selected_type(value) {
        this.initialize_load_stock({
          type: value,
        })
          .then(response => {
            this.car_brand_items = response.data[0].details
            this.data_items = response.data[0].data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('inventory_particulars_id', 2);
          data.append('equipment', '');
          data.append('equipment_brand_description', '');
          data.append('equipment_serial_no', '');
          data.append('equipment_model_color', '');
          data.append('equipment_date_procure', this.car_brand_date_procure);
          data.append('equipment_date_release', '');
          data.append('equipment_unit_price', 0);
          data.append('equipment_unit_quantity', 0);
          data.append('is_temporary', this.is_temporary ? 1 : 0);

          data.append('auto_dealer', this.car_brand);
          data.append('auto_unit', this.auto_unit.toUpperCase());
          data.append('auto_mv_file_no', this.auto_mv_file_no.toUpperCase());
          data.append('auto_plate_no', this.auto_plate_no.toUpperCase());
          data.append('auto_unit_color', this.auto_unit_color.toUpperCase());
          data.append('auto_chasis_no', this.auto_chasis_no.toUpperCase());
          data.append('auto_engine_no', this.auto_engine_no.toUpperCase());
          data.append('auto_unit_value', this.auto_unit_value);
          data.append('year_model', this.year_model);
          this.register_stock(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.selected_type('Car')
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
